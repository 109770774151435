body {
    background-image: url('../images/background.png');
    background-size: 100vw 100vh;
    margin: 0;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    color: white;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
}

.top-section {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 20px;
    padding-top: 10px;
    width: 100vw;
    height: 20vh;
}

.top-section h2, h4 {
    margin: 0;
}

.top-section h2 {
    font-size: 24px;   
}

.top-section h4 {
    font-size: 10px;   
}

.top-section p {
    font-size: 14px;
    margin-top: 15px;
}

.left-section, .right-section {
    width: 35%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.title {
    position: relative;
    overflow: hidden;
    height: 100px;
    width: 90%;
    padding-right: 10px;
    left: 25%;
}

.title h2, h4 {
    margin: 0;
}

.title h2 {
    font-size: 24px;   
}

.title h4 {
    font-size: 10px;   
}

.title p {
    font-size: 14px;
    margin-top: 20px;
}

.center-section {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headshot-image {
    position: relative;
    width: 160px;
    height: 220px;
    overflow: hidden;
    transform: rotate(25deg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.headshot-image-egg {
    background-color: grey;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
}

.headshot-image-egg img {
    width: 100%;
    height: auto;
    padding-top: 52px;
    transform: rotate(-25deg);
}

.icons-container {
    position: absolute;
    display: flex;
    justify-content: right;
    align-items: center;
    height: 5vh;
    width: 30vw;
}

.line-container-left {
    position: absolute;
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100vh;
    width: 50vw;
    margin-top: 8vh;
    z-index: -1;
}

.line-container-right {
    position: absolute;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100vh;
    left: 50vw;
    width: 50vw;
    margin-top: -4vh;
    z-index: -1;
}

.right-line {
    width: 40vw;
    height: 3px;
    background-color: grey;
}

.left-line {
    width: 40vw;
    height: 3px;
    background-color: grey;
}

@media only screen and (min-width: 0px) and (max-width: 620px) {
    .icons-container {
        right: 8vw;
    }
}

@media only screen and (min-width: 621px) and (max-width: 750px) {
    .icons-container {
        right: 8.5vw;
    }
}

@media only screen and (min-width: 751px) and (max-width: 1130px) {
    .icons-container {
        right: 9vw;
    }
}

@media only screen and (min-width: 1131px) and (max-width: 1500px) {
    .icons-container {
        right: 9.25vw;
    }
}

@media only screen and (min-width: 1501px) {
    .icons-container {
        right: 9.5vw;
    }
}